.Dashboard {
  display: flex;
  height: calc(100vh - 20px);
  background-color: gray;
  margin-top: 20px;
  margin-left: 20px;
  border-radius: 15px 0 0 0;
  overflow: hidden;
}
.dashboardCtn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .Dashboard {
    margin-top: 5px;
    margin-left: 5px;
    height: calc(100vh - 5px);
  }
}