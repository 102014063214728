.AsideMenu {
  width: 60px;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 20px 0;
  box-sizing: border-box;
}
.iconCtn {
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 8px solid transparent;
  transition: all 0.2s;
}
.iconCtnSelect {
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 8px solid var(--orange);
  background-color: var(--primaryLight);
  transition: all 0.2s;
}
.iconCtn:hover {
  background-color: var(--primaryLight);
  transition: all 0.2s;
}
.icons {
  color: var(--light);
  font-size: 1.7rem;
  cursor: pointer;
  transition: all 0.2s;
}
.icons:hover {
  color: var(--orange);
  transition: all 0.2s;
}

@media only screen and (max-width: 500px) {
  .AsideMenu {
    box-sizing: content-box;
  }
}