.Pagination {
  display: flex;
  align-self: flex-end;
  margin: 10px 0;
  user-select: none;
}
.arrowCtn {
  margin: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background-color: var(--light);
  border: 1px solid var(--shadow);
  color: var(--primaryDark);
  border-radius: 3px;
}

.arrowCtnDisabled {
  margin: 0 10px;
  display: flex;
  align-items: center;
  cursor: default;
  justify-content: center;
  background-color: var(--shadow);
  border: 1px solid var(--shadow);
  color: var(--dark);
  border-radius: 3px;
}

.arrowCtn:hover {
  background-color: var(--shadow);
}

.arrowCtn svg, .arrowCtnDisabled svg {
  font-size: 1.4rem;
}

.arrowCtn svg:first-child, .arrowCtnDisabled svg:first-child {
  margin-right: 5px;
}

.arrowCtn svg:last-child, .arrowCtnDisabled svg:last-child {
  margin-left: 5px;
}

.arrowCtn span:first-child, .arrowCtnDisabled span:first-child {
  margin-left: 5px;
}

.arrowCtn span:last-child, .arrowCtnDisabled span:last-child {
  margin-right: 5px;
}

@media only screen and (max-width: 500px) {
  .Pagination {
    align-self: center;
    margin-top: 20px;
  }
}