.Main {
  background-color: var(--light);
  display: flex;
  flex-direction: column;
}
.iconsBar {
  display: flex;
  background-color: white;
  height: 50px;
  border-radius: 15px 15px 0 0;
  padding-top: 20px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
.labelCtn {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 20px;
  height: calc(100vh - 210px);
  padding: 20px;
  overflow-y: scroll;
  box-sizing: border-box;
  border-bottom: 2px solid var(--shadow);
  position: relative;
}
.labelCtn::-webkit-scrollbar {
  width: 0.5rem;
}
.labelCtn::-webkit-scrollbar-track {
  background: var(--shadow);
 }

 .labelCtn::-webkit-scrollbar-thumb {
  background: var(--dark);
 }

.label {
  width: 60px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.labelActive {
  border-bottom: 2px solid var(--orange);
  z-index: 100;
  color: var(--primary);
  padding-bottom: 5px;
  transition: all 0.2s;
}
.labelOff {
  border-bottom: 2px solid white;
  z-index: 99;
  color: var(--shadow);
  padding-bottom: 5px;
  transition: all 0.2s;
}
.badge {
  position: absolute;
  left: 95px;
  top: 15px;
  font-size: 0.6rem;
  background-color: red;
  color: white;
  width: 13px;
  height: 13px;
  border-radius: 20px;
  text-align: center;
  z-index: 100;
  user-select: none;
}

@media only screen and (max-width: 500px) {
  .labelCtn {
    margin: 0px 10px 20px 10px;
    height: calc(100vh - 190px);
  }
  .iconsBar {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 412px) {
  .labelCtn {
    margin: 0 3px 10px 3px;
    padding: 10px;
    height: calc(100vh - 180px);
  }
  .iconsBar {
    margin-left: 3px;
    margin-right: 3px;
    padding-top: 20px;
  }
}