.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formContainer {
  display: flex;
  margin-top: -50px;
  border-radius: 5px;
  justify-content: center;
  overflow: hidden;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
}
.imageContainer img {
  width: 100%;
  max-width: 200px;
  margin: 10px 0;
  padding: 0 10px;
  box-sizing: border-box;
}
.formContainer form {
  padding: 15px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 300px;
}
.formContainer form input {
  border-bottom: 1px solid var(--dark);
}
.formContainer label {
  color: white;
}

.formContainer span {
  color: var(--dark);
  font-size: 0.9rem;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;
}

.formContainer form input:last-child {
  align-self: flex-end;
  transition: all 0.3s;
}
.formContainer form input:last-child:hover {
  color: var(--shadow);
  transition: all 0.3s;
}
.formContainer form input:nth-child(1) {
  width: 100%;
}

.formContainer h2 {
  color: var(--primary);
  user-select: none;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.formContainer h4 {
  color: var(--dark);
  user-select: none;
  margin-bottom: 10px;
  font-weight: 400;
}
.passContainer {
  display: flex;
  align-items: center;
  position: relative;
}
.passContainer svg {
  position: absolute;
  right: 3px;
  font-size: 1.1rem;
  cursor: pointer;
  color: var(--dark);
}
.message {
  margin: 5px 0;
}
.message p {
  font-size: 0.7rem;
  color: var(--dark);
}

@media only screen and (max-width: 528px) {
  .formContainer {
    flex-direction: column;
  }
  .formContainer form {
    min-width: 250px;
  }
}