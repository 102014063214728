.Users {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 5px 0 5px;
  /* justify-content: space-between;
  align-items: flex-start; */
}
.cardsCtn {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.addBtn {
  border: none;
  background-color: var(--primary);
  color: var(--light);
  padding: 8px 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 2px var(--shadow);
  cursor: pointer;
  text-transform: uppercase;
  align-self: flex-end;
}
.addBtn:hover {
  background-color: var(--primaryLight);
}
