.Products {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.cardContainer {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--shadow);
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
}
.empty {
  font-size: 1rem;
  margin-top: 10px;
  color: var(--orange);
  font-style: italic;
}
.inputPerc {
  justify-content: start;
  align-self: flex-end;
  margin: 10px 0;
}
.input {
  background-color: white;
  border: 1px solid var(--shadow);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.input svg {
  background-color: var(--primary);
  padding: 5px;
  color: white;
  font-size: 1rem;
}
.input input {
  background-color: transparent;
  border: none;
  padding: 5px;
}
.input input::placeholder {
  color: var(--shadow);
}
.input input:focus-visible {
  outline: none;
}