.CardProduct {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--shadow);
  background-color: var(--light);
  border-radius: 10px;
  overflow: hidden;
  color: var(--primary);
  padding: 10px;
  box-sizing: border-box;
  width: calc(33.33% - 20px);
  margin: 10px; 
}
.cardCtn {
  display: flex;
}
.productImageCtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}
.productImage {
  max-width: 120px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.productImageCtn:hover > .imageHover {
  display: flex;
  opacity: 1;
  transition: all 0.3s;
}
.imageHover {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.351);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
.imageZoom {
  position: absolute;
  max-width: 400px;
  top: 20px;
  left: 20px;
  cursor: pointer;
  z-index: 100;
  border-radius: 15px;
  border: 1px solid var(--shadow);
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
}
.title {
  font-size: 1rem;
  font-weight: 500;
}
.categoriesName {
  font-size: 0.8rem;
  padding: 2px 5px;
  background: white;
  margin-right: 5px;
  border: 1px solid var(--shadow);
  border-radius: 20px;
  user-select: none;
}
.info a {
  text-decoration: none;
  color: var(--orange);
  font-size: 0.9rem;
}
.descriptionSeparator {
  border-top: 1px solid var(--shadow);
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.more {
  font-size: 1.8rem;
  user-select: none;
  cursor: pointer;
}
.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.description ul {
  list-style: none;
}
.description h2, .description h3 {
  font-size: 1.4rem;
  font-weight: 500;
}
.addButton {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
}
.addButton svg {
  font-size: 1.8rem;
  cursor: pointer;
  color: var(--primary);
}
.addButton svg:hover {
  color: var(--primaryLight);
}
.input {
  background-color: white;
  border: 1px solid var(--shadow);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.input svg {
  background-color: var(--primary);
  padding: 5px;
  color: white;
  font-size: 1rem;
}
.input input {
  background-color: transparent;
  border: none;
  padding: 5px;
}
.input input::placeholder {
  color: var(--shadow);
}
.input input:focus-visible {
  outline: none;
}

@media only screen and (max-width: 2000px) {
  .CardProduct {
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 1200px) {
  .CardProduct {
    width: 100%;
  }
}

@media only screen and (max-width: 660px) {
  .CardProduct {
    padding: 10px 10px 0 10px;
  }
  .cardCtn {
    flex-direction: column;
    align-items: flex-start;
  }
  .addButton {
    align-self: flex-end;
    margin-top: 8px;
  }
  .info a {
    margin: 8px 0;
  }
  .title {
    margin: 8px 0;
  }
  .input {
    align-self: start;
    margin: 8px 0;
  }
}

@media only screen and (max-width: 500px) {
  .description ul {
    padding-inline-start: 5px;
  }
  .imageZoom {
    position: absolute;
    max-width: 90%;
    top: 10px;
    left: 10px;
    cursor: pointer;
    z-index: 100;
    border-radius: 15px;
    border: 1px solid var(--shadow);
  }
  .productImageCtn {
    margin-bottom: 10px;
  }
  .title {
    font-size: 0.9rem;
  }
  .categoriesName {
    font-size: 0.7rem;
  }
  .info a {
    font-size: 0.8rem;
  }
}
