.Title {
  color: var(--primaryLight);
  font-weight: 500;
}

.default {
  font-size: 1rem;
}

.small {
  font-size: 0.8rem;
}

.big {
  font-size: 1.2rem;
}

@media only screen and (max-width: 500px) {
  .default {
    font-size: 0.8rem;
  }
  .big {
    font-size: 1rem;
  }
  .small {
    font-size: 0.6rem;
  }
}