.Profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--light);
  height: 100%;
  padding: 20px;
}
.Profile h4 {
  color: var(--dark);
  padding: 0 10px;
}
.Profile form {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--shadow);
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: white;
}
.Profile form input {
  border-bottom: 1px solid var(--dark);
}
.info {
  padding: 10px;
  color: var(--dark);
  border-radius: 10px;
  border: 1px solid var(--shadow);
  margin-bottom: 10px;
  box-shadow: 1px 1px 2px var(--shadow);
  background-color: white;
}
.info p {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

@media only screen and (max-width: 320px) {
  .Profile {
    padding: 5px;
  }
}