.inputPerc {
  justify-content: start;
  align-self: flex-end;
  margin-left: 5px;
  margin-bottom: 5px;
  /* padding: 0px 3px 3px 0; */
}
.input {
  background-color: white;
  border: 1px solid var(--shadow);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.input svg {
  background-color: var(--primary);
  padding: 8px;
  color: white;
  font-size: 0.8em;
}
.input input {
  background-color: transparent;
  border: none;
  padding: 5px;
}
.input input::placeholder {
  color: var(--shadow);
}
.input input:focus-visible {
  outline: none;
}

@media only screen and (max-width: 500px) {
  .inputPerc {
    justify-content: start;
    align-self: flex-start;
    margin-left: 5px;
    margin-bottom: 5px;
  }
}