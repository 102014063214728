.standard {
  border: none;
  padding: 8px;
  border-radius: 3px;
  margin: 5px 0;
  box-sizing: border-box;
  /* width: 100%; */
}
.standard:focus-visible {
  outline: 0;
}
.standard::placeholder {
  color: var(--shadow);
}
.standard:disabled {
  background-color: var(--light);
  color: var(--shadow);
}
.standard::-webkit-outer-spin-button,
.standard::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.light {
  background-color: white;
  color: var(--dark);
}
.dark {
  background-color: var(--primary);
  color: var(--light);
}
.dark::placeholder {
  color: grey;
}
.normal {
  background-color: var(--light);
}
.btnSubmit {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 1px 1px 2px var(--shadow);
  background-color: var(--primaryLight);
  align-self: flex-start;
}
.btnSubmit:hover {
  background-color: var(--primary);
}