.AddUser {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--light);
  height: 100%;
  padding: 20px;
}

.AddUser form {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--shadow);
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: white;
}

.buttonsAdd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-top: 1px solid var(--shadow);
  padding: 5px 0;
}
.cancelBtn {
  border: none;
  background-color: var(--shadow);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 1px 1px 2px var(--primaryLight);
  align-self: flex-end;
  color: var(--primaryLight);
  padding: 8px;
  border-radius: 3px;
  margin: 5px 0;
  font-size: 0.6rem;
}