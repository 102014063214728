.User {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--shadow);
  background-color: var(--light);
  border-radius: 10px;
  color: var(--primary);
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  user-select: none;
}

.info {
  display: flex;
  flex-direction: column;
}
.item {
  display: flex;
  align-items: center;
  margin: 5px;
}
.item svg {
  margin-right: 5px;
}

.buttons {
  border-left: 1px solid var(--shadow);
  display: flex;
  align-items: flex-end;
  padding: 0 10px;
  margin: 0 5px;
}
.buttons svg {
  padding: 10px;
  font-size: 1.5rem;
  margin: 0 5px;
  cursor: pointer;
}
.buttons svg:hover {
  color: var(--primaryLight);
}

@media only screen and (max-width: 550px) {
  .User {
    flex-direction: column;
    align-items: flex-start;
  }
  .buttons {
    justify-content: flex-end;
    border-top: 1px solid var(--shadow);
    border-left: none;
    padding: 5px 0;
    margin: 5px 0;
    width: 100%;
  }
}