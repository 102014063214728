.CardCot {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--shadow);
  background-color: var(--light);
  border-radius: 10px;
  overflow: hidden;
  color: var(--primary);
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  width: calc(33.33% - 20px);
  margin: 10px;
}
.paused {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.704);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
}

.cardCtn {
  display: flex;
}
.productImageCtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}
.productImage {
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
}
.title {
  font-size: 1rem;
  font-weight: 500;
}
.categoriesName {
  font-size: 0.8rem;
  padding: 2px 5px;
  background: white;
  margin-right: 5px;
  border: 1px solid var(--shadow);
  border-radius: 20px;
  user-select: none;
}
.price {
  font-size: 0.9rem;
}
.info a {
  text-decoration: none;
  color: var(--orange);
  font-size: 0.9rem;
}
.descriptionSeparator {
  border-top: 1px solid var(--shadow);
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.more {
  font-size: 1.8rem;
  user-select: none;
  cursor: pointer;
}
.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.description ul {
  list-style: none;
}
.description h2, .description h3 {
  font-size: 1.4rem;
  font-weight: 500;
}
.right {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.addButton {
  display: flex;
  align-items: center;
}
.addButton svg:first-child, .addButton svg:last-child {
  z-index: 200;
}
.addButton svg {
  font-size: 1.4rem;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 15px;
  margin-top: 10px;
}

.percCtn {
  display: flex;
}

@media only screen and (max-width: 2000px) {
  .CardCot {
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 1200px) {
  .CardCot {
    width: 100%;
  }
}

@media only screen and (max-width: 660px) {
  .CardCot {
    padding: 10px 10px 0 10px;
    margin: 10px 0;
  }
  .cardCtn {
    flex-direction: column;
    align-items: flex-start;
  }
  .right {
    width: 100%;
  }
  .addButton {
    margin-top: 8px;
  }
  .addButton svg {
    font-size: 1.6rem;
  }
  .info a {
    margin: 8px 0;
  }
  .title, .categoriesName {
    margin: 8px 0;
  }
  .price {
    margin: 5px 0;
  }
  
}
@media only screen and (max-width: 500px) {
  .description ul {
    padding-inline-start: 5px;
  }
  .productImageCtn {
    margin-bottom: 10px;
  }
  .title {
    font-size: 0.9rem;
  }
  .categoriesName {
    font-size: 0.7rem;
  }
  .info a {
    font-size: 0.8rem;
  }
  .percCtn {
    flex-direction: column;
    align-items: flex-start;
  }
}