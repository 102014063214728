.Header {
  height: 80px;
  background-color:white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  user-select: none;
  border-bottom: 0.3px solid var(--shadow);
}
.right img {
  max-width: 150px;
  cursor: pointer;
  margin-right: 30px;
}
.right {
  display: flex;
  align-items: flex-end;
}
.left {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: 5px;
}
.left p, .buttonOff, .arrow {
  color: var(--dark);
}
.left p {
  margin-right: 20px;
}
.arrow, .buttonOff {
  margin-right: 5px;
  cursor: pointer;
}
.profileButton {
  position: absolute;
  top: 50px;
  background-color: var(--light);
  color: var(--dark);
  font-size: 0.9rem;
  padding: 10px;
  box-shadow: 1px 1px 3px var(--shadow);
  border-radius: 2px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .Header {
    padding: 0 10px;
  }
  .right p {
    display: none;
  }
  .left p {
    margin-right: 5px;
  }
  .buttonOff {
    position: absolute;
    left: 35px;
    top: 50px;
    color: white;
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 500px) {
  .buttonOff {
    left: 20px;
    top: 35px;
  }
  .profileButton {
    top: 35px;
  }
}

@media only screen and (max-width: 412px) {
  .Header {
    flex-direction: column-reverse;
  }
  .left {
    align-self: flex-end;
  }
  .right {
    margin-bottom: 5px;
    align-self: flex-start;
  }
}