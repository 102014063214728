.SearchBar{
  border: 1px solid var(--shadow);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  max-width: 250px;
  width: 250px;
  margin-bottom: 10px;
}
.input {
  border: none;
  background-color: transparent;
  width: 100%;
  color: var(--primaryLight);
}
.input::placeholder{
  color: var(--shadow);
}
.BsSearch{
  color: var(--primaryLight);
  cursor: pointer;
}
.input:focus-visible{
  outline: 0;
}

@media only screen and (max-width: 500px) {
  .input {
    max-width: 250px;
  }
}

@media only screen and (max-width: 400px) {
  .SearchBar {
    width: auto;
    max-width: 250px;
  }
}