:root {
  --primary: #0a1b2b;
  --primaryLight: #133656;
  --orange: #f9c700;
  --dark: #334862;
  --light: #efefef;
  --shadow: #dcdcdc;
  --black: rgb(23, 23, 24);
  --menu: #4a7bb7;
}
* {
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(49deg, rgba(57,99,139,1) 0%, rgba(61,107,153,1) 35%, rgba(39,83,126,1) 35%, rgba(25,61,96,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
